import * as React from "react"

import Layout from "../components/layout"
import { Header } from "../components/header"
import { ImageWithText } from "../components/imageWithText"

const Womans = () => {
  const sections = [
    {
      header: "Dla kobiet",
      text: "W gabinecie Reha-Help zajmujemy się problemami z którymi borykają się kobiety w różnych okresach życia. Jednym z nich są bolesne miesiączki, ile razy słyszałaś że to normalne? Skoro ta przypadłość dotyczyła Twojej mamy czy babci, to Ciebie też musi dotyczyć? Otóż nie. Ból w trakcie menstruacji nie jest czymś normalnym. Można znacznie zmniejszyć dolegliwości a nawet całkiem je usunąć, korzystając z technik manipulacji wisceralnych w obrębie jamy brzusznej. Terapia ta jest w pełni bezpieczna i delikatna, może z niej skorzystać kobieta w każdym wieku, nie stosuje się wejścia przez pochwę, pracujemy technikami zewnętrznymi co gwarantuje naszym pacjentkom całkowite poczucie komfortu.",
      img: "images/icons/womanBlue.svg",
    },
    {
      header: "Ciąża",
      text: "Wiele kobiet w trakcie ciąży cierpi na różne dolegliwości. Ból kręgosłupa, kończyn, zgaga, spuchnięte nogi, wzmożone napięcie. Fizjoterapia stosowana w tym okresie jest całkowicie bezpieczna zarówno dla mamy jak i dla dziecka. Przynosi ulgę w bólu bez użycia środków farmakologicznych. Jeśli ciąża przebiega prawidłowo można korzystać z terapii już w drugim trymestrze.",
      img: "images/icons/shapeBlue.svg",
    },
    {
      header: "Cięcie cesarskie",
      text: "Prawidłowe opracowanie blizny po cieciu cesarskim może zapobiec późniejszym powikłaniom. Jeśli nie zadbamy odpowiednio o obszar w obrębie cięcia może skutkować to pojawieniem się zrostów czy endometriozy. Blizna może również rzutować ból na kręgosłup czy kończyny. To bardzo ważne aby przywrócić elastyczność tkanek zwłaszcza jeśli planowana jest kolejna ciąża.",
      img: "images/icons/scalpelBlue.svg",
    },
    {
      header: "Wysiłkowe nietrzymanie moczu",
      text: "Jeśli podczas kaszlu, kichania lub podnoszenia ciężkich przedmiotów dochodzi do wyciekania moczu, świadczy to o zaburzonej pracy zwieraczy. Fizjoterapia w połączeniu z terapią manualną uchroni przed opadaniem narządów rodnych oraz osłabieniem mięśni dna miednicy. Powstrzyma także nawracające zapalenie pęcherza i dróg moczowych.",
      img: "images/icons/vaginBlue.svg",
    },
  ]
  return (
    <Layout>
      <Header pageName="womans" text="Dla Kobiet" />
      {sections.map(({ header, text, img }) => (
        <ImageWithText
          header={header}
          text={text}
          imgSrc={img}
          contain={true}
        />
      ))}
    </Layout>
  )
}

export default Womans
