import React, { useState } from "react"
import { Alert, Col, Container, Row } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"

export const ImageWithText = ({
  header,
  text,
  imgSrc,
  isContact,
  shadow,
  contain,
}) => {
  const imgProps = {
    height: "30px",
    width: "30px",
    style: { marginRight: ".5rem" },
  }

  const [isCopied, setCopied] = useState(false)

  const contact = (
    <div>
      <Alert className="alert" isOpen={isCopied} color="primary" dismissible>
        Skopiowano do schowka!
      </Alert>
      <h1 className="font-size-24 font-weight-700">
        Jeśli nie odbieram, proszę o cierpliwość, na pewno oddzwonię ;)
      </h1>

      <div
        className="d-flex align-items-center justify-content-between"
        style={{ padding: "1rem 0" }}
      >
        <div>
          <img src="/images/icons/phoneBlue.svg" {...imgProps} />
          508 173 097
        </div>

        <CopyToClipboard
          text={"508 173 097"}
          onCopy={() => {
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 3000)
          }}
        >
          <button className="btn--custom">Kopiuj</button>
        </CopyToClipboard>
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ padding: "1rem 0" }}
      >
        <div>
          <img src="/images/icons/emailBlue.svg" {...imgProps} />
          ankakulach@gmail.com
        </div>
        <CopyToClipboard
          text={"ankakulach@gmail.com"}
          onCopy={() => {
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 3000)
          }}
        >
          <button className="btn--custom">Kopiuj</button>
        </CopyToClipboard>
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ padding: "1rem 0" }}
      >
        <div>
          <img src="/images/icons/instagramBlue.svg" {...imgProps} />
          @reha_help_fizjoterapia
        </div>
        <button
          onClick={() =>
            (window.location.href =
              "https://www.instagram.com/reha_help_fizjoterapia")
          }
          className="btn--custom"
        >
          Odwiedź
        </button>
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ padding: "1rem 0" }}
      >
        <div>
          <img src="/images/icons/facebookBlue.svg" {...imgProps} />
          Reha - Help Fizjoterapia
        </div>
        <button
          className="btn--custom"
          onClick={() =>
            (window.location.href =
              "https://www.facebook.com/Reha-Help-Fizjoterapia-114275583658489")
          }
        >
          {" "}
          Odwiedź
        </button>
      </div>
    </div>
  )

  return (
    <Container className="IWT--container">
      <Row>
        <Col sm={8} className="d-flex align-items-start">
          <img src="/images/dots.svg" />
          {isContact ? (
            <>{contact}</>
          ) : (
            <div>
              <h1 className="font-size-24 font-weight-700">{header}</h1>
              <p className="font-size-24">{text}</p>
            </div>
          )}
        </Col>
        <Col sm={4} className="d-flex flex-column justify-content-center">
          <img
            style={{
              objectFit: `${contain && "contain"}`,
              padding: `${contain && "4rem"}`,
            }}
            className={`IWT--image ${shadow && "image-shadow"}`}
            src={imgSrc}
          />
        </Col>
      </Row>
    </Container>
  )
}
